<template>
  <img src="@/assets/img/banner.png" alt="" class="banner w-100">
</template>

<script>
export default {

}
</script>

<style scoped>
.banner{
    object-fit: cover;
}
</style>