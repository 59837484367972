<template>
  <div id="Statistics">
    <SubHeader title="통계" :link="'/home'"></SubHeader>
    <div class="px-3 mb-3">
      <ul class="d-flex gap-2 mb-2">
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(0)"
            :class="{ 'bg-gray-relative-300': cate === 0 }"
            >전체</span
          >
        </li>
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(1)"
            :class="{ 'bg-gray-relative-300': cate === 1 }"
            >1일</span
          >
        </li>
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(2)"
            :class="{ 'bg-gray-relative-300': cate === 2 }"
            >7일</span
          >
        </li>
        <li class="w-25">
          <span
            class="w-100 badge bg-white border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3"
            @click="ChangeDate(3)"
            :class="{ 'bg-gray-relative-300': cate === 3 }"
            >30일</span
          >
        </li>
      </ul>
      <div class="d-flex gap-2 mb-2">
        <Datepicker :value="start_date" @change="handleStartDate"></Datepicker>
        <Datepicker :value="end_date" @change="handleEndDate"></Datepicker>
        <button
          class="btn border-0 btn-sm bg-secondary text-white w-px-80 flex-shrink-0"
          @click="GetStatic()"
        >
          조회
        </button>
      </div>
    </div>

    <div class="p-3 pt-0">
      <h6 class="py-2 mb-2"><i class="fal fa-wreath me-2"></i>판매 통계</h6>

      <div>
        <ul class="d-flex gap-2 w-100 mb-2">
          <li
            class="border p-2 ms-px--1 flex-grow-1 rounded-3 w-33"
            :class="{ 'bg-main text-white border-main': selected_type == 'P' }"
            @click="selectTypeFn('P')"
          >
            <p class="mb-2 small opacity-75">거래금액</p>
            <div class="text-end d-flex flex-column">
              <p class="fs-6 lh-1 flex-grow-1 h-100">
                {{ CheckNumber(total_price) }}<span><small>원</small></span>
              </p>
              <div class="opacity-75 lh-1 small">
                <small>{{ CheckNumber(total_count) }}</small>
                <small class="fs-px-12">건</small>
              </div>
            </div>
          </li>
          <li
            class="border p-2 ms-px--1 flex-grow-1 rounded-3 w-33"
            :class="{ 'bg-main text-white border-main': selected_type == 'C' }"
            @click="selectTypeFn('C')"
          >
            <p class="mb-2 small opacity-75">정산금액</p>
            <div class="text-end d-flex flex-column">
              <p class="fs-6 lh-1 flex-grow-1 h-100">
                {{ CheckNumber(total_calculate) }}<span><small>원</small></span>
              </p>
              <div class="opacity-75 lh-1 small">
                <small>{{ CheckNumber(total_count) }}</small>
                <small class="fs-px-12">건</small>
              </div>
            </div>
          </li>
          <li
            class="border p-2 ms-px--1 flex-grow-1 rounded-3 w-33"
            :class="{ 'bg-main text-white border-main': selected_type == 'T' }"
            @click="selectTypeFn('T')"
          >
            <p class="mb-2 small opacity-75">이용자수</p>
            <div class="text-end d-flex flex-column">
              <div class="opacity-75 lh-1 small">
                <small>{{ CheckNumber(member_count) }}</small>
                <small class="fs-px-12">명</small>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- <apexchart ref="chart" height="200" :options="chartOptions" :series="graphRenderFn"></apexchart> -->

      <div class="rounded-2 p-3 calculate_box">
        <button
          class="btn border-0 btn-sm btn-primary text-body-i position-absolute cal_btn"
        >
          <small>대금 지급 주기</small><br />D+{{ calc_day }}일
        </button>
        <small class="fs-px-12">총 매출액</small>
        <h5 class="fw-bold mb-3">{{ CheckNumber(total_price) }} 원</h5>

        <div class="flex-between-center fs-px-11">
          <span>마지막 정산일 </span>
          <span>{{ last_date }}</span>
        </div>
        <hr />
        <div class="flex-between-center mb-1">
          <small class="fs-px-12">지급 예정 금액</small>
          <p class="fs-px-13 mb-0">{{ CheckNumber(total_price) }} 원</p>
        </div>
        <div class="flex-between-center mb-1">
          <small class="fs-px-12">수수료 {{ fee }}%</small>
          <p class="fs-px-13 text-danger mb-0">
            - {{ CheckNumber(total_fee) }} 원
          </p>
        </div>
        <div class="flex-between-center mb-3">
          <span class="fs-px-12 fw-bold">실지급 금액</span>
          <h5>{{ total_calculate }} 원</h5>
        </div>
        <p class="text-muted fs-px-11">
          <i class="far fa-exclamation-circle me-1"></i>대금 지급 주기는 영업일
          기준이며 주말 및 공휴일은 포함되지 않습니다. 정산 계좌 변경을 원할 시,
          관리자에 문의하세요.
        </p>
      </div>
      <div>
        <h6 class="py-2 mb-2">
          <i class="fal fa-table me-2 mt-4"></i>세부내역
        </h6>
        <table class="table small text-center">
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">건수</th>
              <th scope="col">금액</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-for="(item, index) in history_list" :key="index">
              <td>{{ item.date }}</td>
              <td>{{ CheckNumber(item.count) }}</td>
              <td>{{ CheckNumber(item.price) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import SubHeader from '@/components/common/SubHeader.vue';
import Advertisement from '@/components/common/Advertisement.vue';
import Datepicker from '@/components/common/Datepicker.vue';
const CryptoJS = require('crypto-js');
var moment = require('moment');

export default {
  components: {
    SubHeader,
    Advertisement,
    apexchart: VueApexCharts,
    Datepicker,
  },
  data() {
    return {
      cate: 0,
      start_date: '',
      end_date: '',
      // start_date:new Date(),
      // end_date:new Date(),
      calc_day: '3',
      dash_total_price: '11213131',
      dash_hold_price: '11213131',
      dash_fee_price: '11213131',
      dash_calc_price: '11213131',
      history_list: [],
      selected_type: 'P',
      total_price: '',
      total_calculate: '',
      last_date: '',
      member_count: '',
      total_fee: '',
      total_count: '',
      total_calculate: '',
      fee: 3,
      // seriesData: [
      //     {
      //         name:'거래 금액',
      //         type:'line',
      //         data: [100000, 410000, 350000, 510000, 490000, 620000, 69000, 91000, 148000],
      //     },
      //     {
      //         name:'정산 금액',
      //         type:'line',
      //         data: [16900, 41000, 350000, 510000, 490000,148000, 620000, 91000, 100000],

      //     },
      //     {
      //         name: "이용자 수",
      //         type:'line',
      //         data: [45, 52, 38, 24, 33, 26, 21, 20, 6],

      //     },
      //     {
      //         name:'거래 건수',
      //         type:'column',
      //         data: [1, 8, 12, 6, 2, 3,0, 5, 4],

      //     }
      // ],

      // chartOptions: {
      //     legend: {
      //         show: false, // 레전드 숨김
      //     },
      //     chart: {
      //         toolbar:false,
      //         tooltip: {
      //             enabled: false,
      //         },
      //         zoom: {
      //             enabled: false
      //         }
      //     },
      //     dataLabels: {
      //     enabled: false
      //     },
      //     stroke: {
      //         curve: 'straight',
      //         colors: ["#11998e"],
      //         width:[2,0]
      //     },

      //     fill:{
      //         colors:["#11998e",'#8bdcff'],
      //     },

      //     markers: {
      //         size: 5,
      //         colors:["#11998e"],
      //         hover: {
      //             size: 8,
      //             strokeWidth: 6,
      //         },
      //     },
      //     grid: {
      //         borderColor: "#eee",
      //         strokeDashArray: 5,
      //         position: "back",
      //     },
      //     xaxis: {
      //         type:"category",
      //         categories: this.generateDateRange(-8, 0),
      //         labels: {
      //             formatter: function (value) {
      //                 return String(value).slice(-2)+'일';
      //             }
      //         },
      //     },
      //     yaxis: [
      //         {
      //             forceNiceScale:false,
      //             labels: {
      //                 formatter: function (value) {
      //                     if (value >= 10000) {
      //                         return (value / 10000) + '만';
      //                     } else {
      //                         return value;
      //                     }
      //                 }
      //             },
      //         },
      //         {
      //             opposite: true,
      //             show:true,
      //         },
      //     ]
      // },
    };
  },
  mounted() {
    this.GetStatic();
    this.GetStaticDashBoard();
  },
  methods: {
    CheckNumber(point) {
      const amount = point * 1;
      return amount.toLocaleString();
    },
    GetStatic() {
      const start_date = this.start_date;
      const end_date = this.end_date;
      const body = { start_date, end_date };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/shop/statistics/GetStatic', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.history_list = body.list;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              window.location.href = '/signin';
            });
          }
        }
      });
    },
    GetStaticDashBoard() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/statistics/GetStaticDashBoard', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.fee = body.info.fee;
              this.cycle = body.info.cycle;
              this.last_date = body.info.last_date;
              this.total_price = body.info.total_price;
              this.total_calculate = body.info.total_calculate;
              this.total_count = body.info.total_count;
              this.member_count = body.info.member_count;
              this.total_fee = body.info.total_fee;
              // this.history_list = body.list;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                window.location.href = '/signin';
              });
            }
          }
        });
    },

    ChangeDate(cate) {
      if (cate == 0) {
        this.start_date = '';
        this.end_date = '';
        this.cate = 0;
      } else if (cate == 1) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-1, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 1;
      } else if (cate == 2) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-7, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 2;
      } else if (cate == 3) {
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().add(-30, 'days').format('YYYY-MM-DD');
        this.start_date = start_date;
        this.end_date = end_date;
        this.cate = 3;
      }
    },
    handleStartDate(date) {
      this.start_date = date;
    },
    handleEndDate(date) {
      this.end_date = date;
    },
    // selectTypeFn(idx){
    //     if(idx===2){
    //         this.chartOptions.yaxis[1].opposite = false;
    //         this.chartOptions.yaxis[1].show = false;
    //     }else{
    //         this.chartOptions.yaxis[1].opposite = true;
    //         this.chartOptions.yaxis[1].show = true;
    //     }
    //     this.chartOptions = { ...this.chartOptions };
    //     this.selected_type = idx;
    //     this.$refs.chart.updateOptions(this.chartOptions);
    // },
    // DateFormmat(date_str){
    //     let date = new Date(date_str);
    //     let month= date.getMonth() +1;
    //     let day  = date.getDate();
    //     return month+"월 "+day+"일"
    // },
    // generateDateRange(startOffset, endOffset) {
    //     const currentDate = new Date();
    //     const result = [];

    //     for (let i = startOffset; i <= endOffset; i++) {
    //         const date = new Date();
    //         date.setDate(currentDate.getDate() + i);
    //         result.push(date.getDate());
    //     }

    //     return result;
    // },
  },
  // computed:{
  //     graphRenderFn(){
  //         if(this.selected_type!=2){
  //             let graph_array = []
  //             graph_array.push(this.seriesData[this.selected_type])
  //             graph_array.push(this.seriesData[3])
  //             return graph_array
  //         }else{
  //             return [this.seriesData[this.selected_type]]
  //         }
  //     },
  //     chartData() {
  //         if (this.items.length > 0) {
  //         this.xAxisCategories = this.generateDateRange(-8, 0);
  //         }

  //         return {
  //         series: this.items,
  //         options: this.chartOptions
  //         };
  //     },
  //     fee(){
  //         return this.now*0.02
  //     },
  //     formattedDate() {
  //         const date = new Date()
  //         date.setDate(date.getDate() - 2) // 2일 전 날짜로 변경
  //         const year = date.getFullYear()
  //         const month = String(date.getMonth() + 1).padStart(2, '0')
  //         const day = String(date.getDate()).padStart(2, '0')
  //         return `${year}-${month}-${day}`
  //     }
  // }
};
</script>

<style lang="scss" scoped>
.calculate_box {
  position: relative;
  background-color: #e2eeff;
  .cal_btn {
    top: 15px;
    right: 15px;
  }
}
</style>
<style>
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  display: none !important;
}
</style>
